<template>
  <div class="page page-application-email">
    <PageHeader
    :icon="$t('APPLICATION_EMAIL.ICON')"
    :title="$t('APPLICATION_EMAIL.TITLES')"
    back="/settings/"
    >
      <template slot="nav">
        <!-- <small><strong>{{$tc('PUBLICATION_URL.TITLE_COUNT',count)}}</strong></small> -->
      </template>

      <v-tooltip bottom transition="none">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            depressed
            color="success"
            class="ml-2"
            v-bind="attrs"
            v-on="on"
            @click="validate"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        {{ $t('COMMON.ADD') }}
      </v-tooltip>

    </PageHeader>

    <v-form ref="form" />
    <v-container fluid class="height-min-90 d-flex flex-column scroll">
      <v-dialog v-model="dialogMacro" width="300px" overlay-color="transparent">
        <v-card>
          <div>
            <v-form ref="formMacro" v-model="validMacro" class="pr-4 pl-4">
              <br />
              <p>Create a macro (without brace nor space).</p>
              <v-row class="mt-0 mb-0">
                <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field
                    v-model="macro"
                    :label="$t('INPUT.MACRO')"
                    :placeholder="$t('INPUT.MACRO')"
                    :rules="[$rules.required]"
                    required
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
            </v-form>
            <div class="text-center pa-4 pt-0">
              <v-btn
                @click="confirmMacro"
                large
                depressed
                rounded
                color=" success custom"
                :disabled="!validMacro"
              >
                {{ $t("COMMON.ADD") }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>

      <v-row class="ma-0">
        <v-col cols="12">
          <!-- <v-toolbar dense flat color="transparent">
            <v-icon color="primary" class="mr-2">{{
              $t("PUBLICATION.REDIRECTION_ICON")
            }}</v-icon>
            <v-toolbar-title>
              {{ $t("PUBLICATION.REDIRECTION") }}
            </v-toolbar-title>

            <v-spacer />
            <v-btn
              depressed
              rounded
              @click="validate"
              class="mr-3"
              color="success"
            >
              <v-icon class="pr-3">mdi-check</v-icon>
              {{ $t("PUBLICATION_URL.SAVE") }}
            </v-btn>
          </v-toolbar> -->

          <v-card flat outlined class="pa-4 mt-4">
            <p>Template</p>
            <v-divider />
            <div>
              <v-chip-group active-class="primary--text" column>
                <v-chip
                  v-if="!template.includes('{subject}')"
                  key="subject-chip"
                  @click="template += '{subject}'"
                  small
                >
                  {subject}
                </v-chip>
                <v-chip v-else key="'subject-chip'" class="muted" small>
                  {{ "{subject}" }}
                </v-chip>
                <template v-for="(macro, i) in macros">
                  <v-chip
                    :key="`chip-${i}`"
                    @click="template += `{${macro.key}\}`"
                    v-if="!template.includes(`{${macro.key}\}`)"
                    small
                  >
                    {{ `{${macro.key}\}` }}
                  </v-chip>
                  <v-chip :key="`chip-${i}`" v-else class="muted" small>
                    {{ `{${macro.key}\}` }}
                  </v-chip>
                </template>
              </v-chip-group>
            </div>

            <div>
              <v-text-field
                class="my-input"
                v-model="template"
                outlined
                rounded
                hide-details
                :rules="[$rules.required]"
                required
              />
              <!-- <v-row class="px-5 pt-2 justify-end">
                <v-btn color="primary">Valider</v-btn>
              </v-row> -->
            </div>

<!--            <div class="mt-4">-->
<!--              <template v-for="(publication, i) in publicationItems">-->
<!--                <div :key="'pub' + i">-->
<!--                  <small>-->
<!--                    <strong>{{ publication.channel.title }}</strong>-->
<!--                    {{ publication.job.title }}-->
<!--                    <v-icon class="mr-2 ml-2" size="14">mdi-link</v-icon>-->
<!--                    <a-->
<!--                      class="force-wrapped"-->
<!--                      :href="publication.url"-->
<!--                      target="black"-->
<!--                    >-->
<!--                      {{ publication.url }}-->
<!--                    </a>-->
<!--                  </small>-->
<!--                </div>-->
<!--              </template>-->
<!--            </div>-->

          </v-card>

          <v-card flat outlined class="mt-4">
            <v-toolbar flat>
              <p class="pt-2 mr-4">{{ $t("INPUT.MACROS") }}</p>
              <v-btn
                @click="editMacro"
                class="mr-3"
                color="secondary black--text"
                depressed
                small
                rounded
              >
                <v-icon class="mr-2" size="16">mdi-plus</v-icon>
                {{ $t("PUBLICATION_URL.ADD_MACRO") }}
              </v-btn>
            </v-toolbar>
            <v-divider />

            <div v-if="macros" class="pb-0 justify-center align-center">
              <v-tabs
                slider-size="2"
                slider-color="#E0BC37"
                class="macro-tabs"
                height="90px"
              >
                <template>
                  <v-tab
                    v-for="(macro, indexMacro) in macros"
                    :key="`${indexMacro}-tab`"
                    grow="true"
                    show-arrows
                  >
                    <div>
                      <div class="text-nowrap text-ellipsis text-h6">
                        {{ `{${macro.key}\}` }}
                      </div>
                      <v-chip
                        v-if="macro.configs.length"
                        color="primary"
                        class="mr-2 ml-2"
                        x-small
                      >
                        {{ macro.configs.length }}
                      </v-chip>
                      <div class="macro-delete-corner">
                        <v-btn @click="removeMacro(indexMacro)" icon small>
                          <v-icon size="16">mdi-trash-can-outline</v-icon>
                        </v-btn>

                        <v-dialog
                          v-model="dialogMacros[indexMacro]"
                          overlay-color="transparent"
                          width="300px"
                        >
                          <v-card class="pa-4">
                            <p>Change macro title</p>
                            <v-text-field
                              v-model="macroKeyTmp"
                              outlined
                              dense
                              :rules="[$rules.required]"
                              required
                            />
                            <div>
                              <v-btn
                                color="success"
                                rounded
                                depressed
                                dark
                                @click="updateMacro(indexMacro)"
                              >
                                <span>APPLY</span>
                              </v-btn>
                            </div>
                          </v-card>
                        </v-dialog>

                        <v-btn
                          color="primary"
                          dark
                          icon
                          small
                          @click="showDialogMacro(indexMacro, true)"
                        >
                          <v-icon size="16">mdi-pencil</v-icon>
                        </v-btn>

                        <!-- </v-menu
                        <v-btn
                        @click="removeMacro(indexMacro)"
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        >
                          <v-icon size="16">mdi-pencil</v-icon>
                        </v-btn> -->
                      </div>
                    </div>

                    <!-- <v-col>
                      <v-row class="pb-2" justify="center">
                      </v-row>
                      <v-row justify="space-around">
                        <div>
                          <v-chip
                            v-if="macro.configs.length"
                            class="mr-2 px-5"
                            color="primary"
                            small
                          >

                          </v-chip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">

                            </template>
                            <span>{{ $t("PUBLICATION_URL.DELETE_MACRO") }}</span>
                          </v-tooltip>
                        </div>
                      </v-row>
                    </v-col> -->
                  </v-tab>
                </template>
                <template>
                  <v-tab-item
                    v-for="(macro, indexMacro) in macros"
                    :key="indexMacro"
                    :transition="false"
                    :reverse-transition="false"
                  >
                    <v-btn
                      color="secondary black--text"
                      class="ma-4"
                      @click="() => addConfig(indexMacro, macro)"
                      small
                      depressed
                      rounded
                    >
                      <v-icon class="mr-2" size="16">mdi-plus</v-icon>
                      <span>{{ $t("APPLICATION_EMAIL.ADD_CONFIG") }}</span>
                    </v-btn>

                    <v-divider />
                    <v-row
                      class="ma-0"
                      v-for="(config, indexConfig) in macro.configs"
                      :key="indexConfig"
                    >
                      <v-col cols="2" class="pr-0">
                        <v-select
                          class="my-input"
                          :label="$t('INPUT.MACRO_TYPE')"
                          item-text="display"
                          item-value="value"
                          :items="[
                            {
                              display: $t('INPUT.MACRO_TYPE_REGEX'),
                              value: 'regex',
                            },
                            {
                              display: $t('INPUT.MACRO_TYPE_DATA'),
                              value: 'data',
                            },
                          ]"
                          v-model="config.type"
                          dense
                          hide-details
                          outlined
                          :rules="[$rules.required]"
                          required
                        >
                          <template slot="prepend">
                            <span class="text-h6 grey--text mr-2">if</span>
                          </template>
                        </v-select>
                      </v-col>

                      <v-col cols="2" class="pr-0">
                        <v-text-field
                          class="my-input"
                          v-model="config.on"
                          :rules="[$rules.required]"
                          outlined
                          dense
                          hide-details
                        />
                      </v-col>

                      <v-col cols="2">
                        <v-text-field
                          class="my-input"
                          v-model="config.trigger"
                          :rules="[$rules.required]"
                          auto-grow
                          prepend-icon="mdi-equal"
                          dense
                          outlined
                          hide-details
                        />
                      </v-col>

                      <v-col cols="5">
                        <v-text-field
                          class="my-input"
                          v-model="config.value"
                          :rules="[$rules.required]"
                          auto-grow
                          prepend-icon="mdi-arrow-right"
                          dense
                          outlined
                          hide-details
                        />
                      </v-col>

                      <v-col cols="1" class="text-right">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              @click="removeConfig(indexMacro, indexConfig)"
                              icon
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("PUBLICATION_URL.REMOVE_CONFIG") }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>

                    <!--
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-row class="macro-line pt-4 pl-5 justify-space-between">
                              <v-text-field
                                class="macro-name"
                                v-model="macro.key"
                                filled
                                rounded
                                dense
                                :rules="[$rules.required]"
                                required
                              />
                              <div>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      @click="removeMacro(indexMacro)"
                                      icon
                                      color="primary"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{$t('COMMON.REMOVE')}}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      color="primary"
                                      class="ml-3 mr-4"
                                      @click="() => addConfig(indexMacro, macro)"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t('PUBLICATION_URL.ADD_CONFIG') }}</span>
                                </v-tooltip>
                              </div>
                            </v-row>
                            <template>
                              <v-row
                                v-for="(config, indexConfig) in macro.configs"
                                :key="indexConfig"
                                class="
                                  macro-line
                                  pt-3
                                  pl-3
                                  pr-3
                                  justify-space-between
                                  align-center
                                "
                              >
                                <v-col
                                  xl="2"
                                  lg="6"
                                  md="6"
                                  sm="12"
                                  class="justify-center"
                                >
                                  <v-select
                                    class="my-input"
                                    :label="$t('INPUT.MACRO_TYPE')"
                                    item-text="display"
                                    item-value="value"
                                    :items="[
                                      {
                                        display: $t('INPUT.MACRO_TYPE_REGEX'),
                                        value: 'regex',
                                      },
                                      {
                                        display: $t('INPUT.MACRO_TYPE_DATA'),
                                        value: 'data',
                                      },
                                    ]"
                                    v-model="config.type"
                                    filled
                                    rounded
                                    outlined
                                    :rules="[$rules.required]"
                                    required
                                  />
                                </v-col>
                                <v-col
                                  xl="3"
                                  lg="6"
                                  md="6"
                                  sm="12"
                                  class="justify-center"
                                >
                                  <v-textarea
                                    :label="$t('INPUT.MACRO_ON')"
                                    class="my-input"
                                    v-model="config.on"
                                    :rules="[$rules.required]"
                                    auto-grow
                                    rows="1"
                                    row-height="15"
                                    outlined
                                  />
                                </v-col>
                                <v-col
                                  xl="3"
                                  lg="6"
                                  md="6"
                                  sm="12"
                                  class="justify-center"
                                >
                                  <v-textarea
                                    :label="$t('INPUT.MACRO_TRIGGER')"
                                    class="my-input"
                                    v-model="config.trigger"
                                    auto-grow
                                    rows="1"
                                    row-height="15"
                                    :rules="[$rules.required]"
                                    required
                                    outlined
                                  />
                                </v-col>
                                <v-col
                                  xl="3"
                                  lg="5"
                                  md="5"
                                  sm="12"
                                  class="justify-center"
                                >
                                  <v-textarea
                                    :label="$t('INPUT.MACRO_VALUE')"
                                    class="my-input"
                                    v-model="config.value"
                                    auto-grow
                                    rows="1"
                                    row-height="15"
                                    :rules="[$rules.required]"
                                    required
                                    outlined
                                  />
                                </v-col>
                                <v-col
                                  xl="1"
                                  lg="1"
                                  md="1"
                                  sm="12"
                                  class="justify-center align-self-stretch"
                                >
                                  <div class="pt-3">
                                    <v-tooltip top>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          @click="removeConfig(indexMacro, indexConfig)"
                                          icon
                                          color="primary"
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon>mdi-trash-can-outline</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{
                                        $t('PUBLICATION_URL.REMOVE_CONFIG')
                                      }}</span>
                                    </v-tooltip>
                                  </div>
                                </v-col>
                              </v-row>
                            </template>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                     -->
                  </v-tab-item>
                </template>
              </v-tabs>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue'
import PageHeader from '@/components/PageHeader'

export default {
  name: "PageSettingsAllApplicationEmail",
  components: {
    PageHeader
  },
  data: (_t) => ({
    dialogMacros: [],
    numberOfMacros: 0,
    macros: [],
    items: [],
    publicationItems: [],

    // form macro
    validMacro: false,
    dialogMacro: false,
    macro: undefined,
    macroToReplace: undefined,
    status: undefined,

    // form macro item
    validMacroItem: false,
    dialogMacroItem: false,
    macroItem: undefined,
    macroItemToReplace: undefined,
    macroKeyTmp: undefined,
    template: "{subject}",
  }),

  mounted() {
    this.loadApplicationEmail();
  },

  watch: {
    template() {
    },
    macros() {
    },
  },

  methods: {
    updateMacro(i) {
      const old = this.macros[i].key;
      this.template = this.template.replace(
        new RegExp(`{${old}}`, "gm"),
        `{${this.macroKeyTmp}}`
      );
      this.macros[i].key = this.macroKeyTmp;
      this.showDialogMacro(i, false);
      this.macroKeyTmp = undefined;
    },

    showDialogMacro(i, v = true) {
      const states = [...this.dialogMacros];
      if (v) {
        states[i] = v;
        this.macroKeyTmp = this.macros[i].key;
        this.dialogMacros = states;
      } else {
        this.dialogMacros = [];
      }
    },

    formatMacros(macros) {
      let formattedMacros = {};
      formattedMacros.subject = true;

      macros.forEach((macro) => {
        formattedMacros[macro.key] = macro.configs;
      });

      return formattedMacros;
    },

    editMacro(key) {
      key = typeof key === "string" ? key : "";
      this.macro = key || null;
      this.dialogMacro = true;
    },

    loadApplicationEmail() {
      this.loading = true;
      return this.$services.api_programmatic.application_email
        .search()
        .then((response) => {
          if (response.data) {
            this.items = response.data;
            this.assignItems(response.data);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    assignItems(items) {
      if (items.length === 1) {
        const data = items[0];

        if (typeof data.macros !== "undefined") {
          for (const [key, value] of Object.entries(data.macros)) {
            if (key !== "subject") {
              this.macros.push({
                id: this.numberOfMacros,
                key,
                type: value[0] ? value[0].type : undefined,
                on: value[0] ? value[0].on : undefined,
                configs: value,
              });
              this.numberOfMacros++;
            }
          }
        }
        if (typeof data.template !== "undefined") this.template = data.template;
        if (typeof data.id !== "undefined") this.id = data.id;
        if (typeof data.status !== "undefined") this.status = data.status;
      }
    },

    addConfig(index) {
      let oldMacro = this.macros[index];
      oldMacro["configs"].push({
        type: oldMacro.type,
        on: oldMacro.on,
        trigger: "",
        value: "",
        default_value: "",
      });
      Vue.set(this.macros, [index], oldMacro);
    },

    removeConfig(indexMacro, indexConfig) {
      let oldMacro = this.macros[indexMacro];
      oldMacro["configs"].splice(indexConfig, 1);

      Vue.set(this.macros, [indexMacro], oldMacro);
    },

    removeMacro(indexMacro) {
      Vue.delete(this.macros, indexMacro);
    },

    validate() {
      if (this.$refs.form.validate()) {
        if (this.items.length > 0) return this.updateItem();
        return this.createApplicationEmail();
      }
    },

    createApplicationEmail() {
      const values = {
        status: this.status,
        template: this.template,
        macros: this.formatMacros(this.macros),
      };

      const query = {
        values,
      };

      this.$services.api_programmatic.application_email
        .create(query)
        .catch((response) => {
          this.$store.dispatch("messenger/add", {
            type: "error",
            code: response.code,
            message: response.message,
            time: 5000,
          });
        });
    },

    updateItem() {
      // prepare values
      const oldValue = this.items[0] || {};
      const values = {};
      if (
        typeof this.template !== "undefined" &&
        this.template !== oldValue.template
      )
        values.template = this.template;
      if (typeof this.status !== "undefined" && this.status !== oldValue.status)
        values.status = this.status;
      if (typeof this.macros !== "undefined")
        values.macros = this.formatMacros(this.macros);

      const query = {
        where: { id: this.items.map((v) => v.id) },
        values,
      };
      this.loading = true;
      this.$services.api_programmatic.application_email
        .update(query)
        .catch((response) => {
          this.$store.dispatch("messenger/add", {
            type: "error",
            code: response.code,
            message: response.message,
            time: 5000,
          });
        });
    },

    confirmMacro() {
      if (this.$refs.formMacro.validate()) {
        const newObject = {
          id: this.numberOfMacros,
          key: this.macro,
          on: undefined,
          type: undefined,
          configs: [],
        };
        this.macros.push(newObject);
        this.dialogMacro = false;
        this.numberOfMacros++;
      }
    },
  },
};
</script>

<style>
.macro-delete-corner {
  position: absolute;
  top: 0;
  right: 0;
}

.v-tab {
  text-transform: none !important;
}

.icon-list {
  padding: 7px;
  background-color: #f5f5f5;
  border-radius: 30px;
}

.my-input.v-input .v-input__slot {
  border-radius: 7px;
}

.macro-select.v-select {
  background-color: #eee;
}

.macro-name {
  max-width: 60%;
  border-radius: 7px;
}

.macro-line {
  border-bottom: 1px solid #ccc;
}

.macro-tabs .v-tab {
  min-width: 180px;
  min-height: 80px;
  border-bottom: 1px solid #ccc;
}

.macro-config-grid {
  border-left: 1px solid #ccc;
}

.force-wrapped {
  word-break: break-word !important;
}
</style>
